
import { defineComponent, onBeforeMount, reactive, toRefs } from "vue";
import TextButton from "@/components/atomics/TextButton.vue";
import Dropdown from "@/components/atomics/Dropdown.vue";
import UpdatePaymentPopup from "@/components/popups/invoice/UpdatePaymentPopup.vue";
import ConfirmDowngradePopup from "@/components/popups/invoice/ConfirmDowngradePopup.vue";
import creditCardIcon from "@/assets/icons/credit-card-icon.svg";
import { formatDate } from "@/lib/utility/common";
import { InvoiceRepository, RepositoryFactory } from "@/lib/https";
import AfterLoginPageLayout from "@/views/layouts/AfterLoginPageLayout.vue";
import UpgradePlanPopup from "@/components/popups/UpgradePlanPopup.vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "Invoice",
  components: {
    TextButton,
    Dropdown,
    UpdatePaymentPopup,
    ConfirmDowngradePopup,
    AfterLoginPageLayout,
    UpgradePlanPopup,
  },
  data() {
    return {
      creditCardIcon: creditCardIcon,
      isUpdatePayment: false,
      isOpenDowngradePopup: false,
    };
  },
  setup() {
    const state = reactive({
      years: [] as any[],
      year: {},
      planInfo: {},
      invoices: [] as any[],
      isShowUpgradePlanPopup: false,
    });
    const store = useStore();
    const { t } = useI18n();
    const { getInvoices, getPlanInfo, updatePaymentMethod } = invoiceMethod();

    const showErrorNotification = () => {
      store.commit(
        "notification/showErrorNotification",
        t("payment.errorCancelPayment")
      );
    };

    onBeforeMount(async () => {
      const payload = await getInvoices(new Date().getFullYear().toString()).catch((e) => {
        // alert(e.data.message);
      });
      if (payload && Object.keys(payload).length !== 0) {
        state.invoices = payload.invoices;
        state.years = (payload.years as number[]).map((item) => {
          return {
            id: item.toString(),
            name: item.toString() + t("invoice.year"),
          };
        });
        state.year = state.years[0];

        pushAllYears();
      }

      state.planInfo = (await getPlanInfo()) as never;
    });

    const getInvoicesByYear = async (year: string) => {
      const payload = await getInvoices(year);
      state.invoices = payload.invoices;
      state.years = (payload.years as number[]).map((item) => {
        return {
          id: item.toString(),
          name: item.toString() + t("invoice.year"),
        };
      });

      pushAllYears();
    };

    const pushAllYears = () => {
      state.years.push({ id: "", name: t("invoice.all") });
    };

    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "JPY",

      // These options are needed to round to whole numbers if that's what you want.
      //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
      //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });

    return {
      ...toRefs(state),
      getInvoices,
      getInvoicesByYear,
      getPlanInfo,
      updatePaymentMethod,
      showErrorNotification,
      formatter,
    };
  },
  methods: {
    invoicePaymentUpdate() {
      this.isUpdatePayment = true;
    },
    onCompletePayment() {
      this.isUpdatePayment = false;
    },
    stopPayment() {
      this.isOpenDowngradePopup = true;
    },
    onConfirmDowngrade() {
      this.isOpenDowngradePopup = false;
      this.$router.go(0);
    },
    onDowngradeError() {
      this.isOpenDowngradePopup = false;
      this.showErrorNotification();
    },
    formatDate(date: string) {
      return formatDate(date, "YYYY/MM/DD");
    },
    onChange(id: string) {
      this.year = this.years.find((year) => year.id === id) ?? this.years[0];
      this.getInvoicesByYear(id);
    },

    onDownloadInvoice(filePath: string) {
      window.open(process.env.VUE_APP_API_CLOUD_URL + filePath);
    },
  },
});
const invoiceMethod = () => {
  const { getInvoices, getPlanInfo, updatePaymentMethod } =
    RepositoryFactory.getRepository<InvoiceRepository>(InvoiceRepository);

  return { getInvoices, getPlanInfo, updatePaymentMethod };
};
