
import { defineComponent, reactive, toRefs, onMounted, computed } from "vue";

import closeIcon from "@/assets/icons/close.svg";
import ValidationTextField from "@/components/atomics/ValidationTextField.vue";
import PaymentButton from "@/components/atomics/PaymentButton.vue";
// import ToggleCheckbox from "@/components/atomics/ToggleCheckbox.vue";
import Popup from "@/components/popups/Popup.vue";
import MenuOptionDropdown from "@/components/atomics/MenuOptionDropdown.vue";
import { useForm } from "vee-validate";
import useFormHandler from "@/lib/compositional-logic/useFormHandler";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import { InvoiceRepository, RepositoryFactory } from "@/lib/https";
export default defineComponent({
  name: "UpdatePaymentPopup",
  components: {
    ValidationTextField,
    PaymentButton,
    // ToggleCheckbox,
    MenuOptionDropdown,
    Popup,
  },
  props: {
    title: { type: String, required: true },
    closeButton: { type: Boolean, default: true },
  },
  emits: ["onClickOutsidePopup", "onHide", "onCompletePayment"],
  setup(prop, { emit }) {
    const router = useRouter();
    const { t } = useI18n();
    const state = reactive({
      closeIcon: closeIcon,
      showInforPopup: false,
      companyId: "",
      cardNo: "",
      expireYear: "",
      expireMonth: "",
      securityCode: "",
      cardName: "",
      tokennumber: "",
      messageError: "",
      isLoading: false,
      bottomTooltip: 14,
    });

    const { updatePaymentMethod } =
      RepositoryFactory.getRepository<InvoiceRepository>(InvoiceRepository);
    onMounted(async () => {
      let script = document.createElement("script");
      const gmoScript = process.env.VUE_APP_GMO_SCRIPT as string;
      const tshop = process.env.VUE_APP_GMO_TSHOP as string;
      script.setAttribute("src", gmoScript);
      script.onload = (e) => {
        (window as any).Multipayment.init(tshop);
      };
      document.head.appendChild(script);
    });
    const doPurchase = async () => {
      state.messageError = "";
      (window as any).Multipayment.getToken(
        {
          cardno: state.cardNo,
          expire: `${state.expireYear}${state.expireMonth}`,
          securitycode: state.securityCode,
          holdername: state.cardName,
          tokennumber: state.tokennumber,
        },
        async (response: any) => {
          state.isLoading = true;
          if (response.resultCode != "000") {
            state.messageError = t("payment.errorFailPayment");
          } else {
            const formData = new FormData();
            formData.append("token", response.tokenObject.token);
            await updatePaymentMethod(formData).catch((e) => {
              state.messageError = e.data.message;
            });
            if (state.messageError == "") {
              emit("onCompletePayment");
              router.push("/invoice/invoice-payment-updated");
            }
          }
          state.isLoading = false;
        }
      );
    };

    const form = useForm();
    const { formHandle } = useFormHandler(form, doPurchase);
    const submit = async function () {
      formHandle();
    };

    const isFullRequireField = computed(() => {
      const { cardNo, expireYear, expireMonth, securityCode, cardName } = state;
      let expire = false;

      const checkYear = parseInt(expireYear);
      const checkMonth = parseInt(expireMonth);
      const currentYear = new Date().getFullYear();
      const currentMonth = new Date().getMonth() + 1;
      if (currentYear < checkYear + 2000 || (checkYear + 2000 == currentYear && currentMonth <= checkMonth)) {
        expire = true;
      }

      return /^(?:4[0-9]{12}(?:[0-9]{3})?|[25][1-7][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13})$/i.test(cardNo) && expire &&
             /^.{3,4}$/i.test(securityCode) && /^[a-zA-Z ]+$/i.test(cardName);
    });

    return { ...toRefs(state), submit, doPurchase, isFullRequireField, form, t };
  },
  methods: {
    clickOutPopup(e: any): void {
      if (e.target.className === "popup-wrapper") {
        this.$emit("onClickOutsidePopup");
      }
    },

    openTooltip() {
      const tooltip = this.$refs.tooltip as HTMLElement;
      this.bottomTooltip = tooltip.offsetTop + 20;
      this.showInforPopup = true;
    },

    hidePopup(): void {
      this.$emit("onHide");
    },

    updateMonth(value: string) {
      const month = parseInt(value);
      if (month > 1 && value.charAt(0) != "0" && month <= 9) {
        this.expireMonth = "0" + value;
      }
      if (month > 12) {
        this.expireMonth = "12";
      }
    },

    onMonthBlur() {
      if (
        this.expireMonth == "1" ||
        this.expireMonth == "0" ||
        this.expireMonth == "00"
      ) {
        this.expireMonth = "01";
      }

      if (!this.checkExpire()) {
        setTimeout(() => {
          this.form.setErrors({
            expireMonth: this.t("errors.errorCardExpired"),
          });
        }, 100);
      }

      this.resetForm();
    },

    onYearBlur() {
      if (!this.checkExpire()) {
        setTimeout(() => {
          this.form.setErrors({
            expireYear: this.t("errors.errorCardExpired"),
          });
        }, 100);
      }

      this.resetForm();
    },

    checkExpire() {
      const checkYear = parseInt(this.expireYear);
      const checkMonth = parseInt(this.expireMonth);
      const currentYear = new Date().getFullYear();
      const currentMonth = new Date().getMonth() + 1;

      if (checkYear + 2000 < currentYear || (checkYear + 2000 == currentYear && checkMonth < currentMonth)) {
        return false;
      }
      else {
        return true;
      }
    },

    resetForm() {
      this.form.resetForm({
        values: {
          cardNumber: this.cardNo,
          expireYear: this.expireYear,
          expireMonth: this.expireMonth,
          cvv: this.securityCode,
          cardName: this.cardName,
        },
      });
    },
  },
});
/**
 * @vuese
 * @group Layout
 * Popup layout
 */
