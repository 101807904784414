import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, vShow as _vShow, normalizeStyle as _normalizeStyle, withCtx as _withCtx, withDirectives as _withDirectives, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8bc98a86"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "popup-container" }
const _hoisted_2 = { class: "payment-message" }
const _hoisted_3 = { class: "message" }
const _hoisted_4 = { class: "payment-card" }
const _hoisted_5 = ["src"]
const _hoisted_6 = ["src"]
const _hoisted_7 = ["src"]
const _hoisted_8 = {
  key: 0,
  class: "payment-error-box mt-5"
}
const _hoisted_9 = { class: "payment-msg-error" }
const _hoisted_10 = { class: "payment-input--area mt-2" }
const _hoisted_11 = { class: "payment-input--item" }
const _hoisted_12 = { class: "input-label" }
const _hoisted_13 = { class: "payment-input--item" }
const _hoisted_14 = { class: "input-label" }
const _hoisted_15 = { class: "payment-input--item" }
const _hoisted_16 = { class: "input-label" }
const _hoisted_17 = { class: "payment-input--group__half" }
const _hoisted_18 = { class: "payment-input--item__half" }
const _hoisted_19 = { class: "payment-input--item__half" }
const _hoisted_20 = { class: "payment-input--item" }
const _hoisted_21 = { class: "input-label" }
const _hoisted_22 = { class: "payment-input--item__custom" }
const _hoisted_23 = ["src"]
const _hoisted_24 = { class: "section-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_validation_text_field = _resolveComponent("validation-text-field")!
  const _component_menu_option_dropdown = _resolveComponent("menu-option-dropdown")!
  const _component_PaymentButton = _resolveComponent("PaymentButton")!
  const _component_Popup = _resolveComponent("Popup")!

  return (_openBlock(), _createBlock(_component_Popup, {
    title: _ctx.title,
    onOnHide: _ctx.hidePopup,
    onOnClickOutsidePopup: _ctx.hidePopup
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t("payment.acceptCreditCardTitle")), 1)
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("img", {
            class: "icon",
            src: require('icons/visa.svg'),
            alt: ""
          }, null, 8, _hoisted_5),
          _createElementVNode("img", {
            class: "icon",
            src: require('icons/master-card.svg'),
            alt: ""
          }, null, 8, _hoisted_6),
          _createElementVNode("img", {
            class: "icon",
            src: require('icons/amex.svg'),
            alt: ""
          }, null, 8, _hoisted_7)
        ]),
        (_ctx.messageError.length > 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
              _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.messageError), 1)
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.$t("payment.cardNumber")), 1),
            _createVNode(_component_validation_text_field, {
              name: "cardNumber",
              class: "input-number",
              placeholder: _ctx.$t('payment.cardNumberPlaceholder'),
              type: "tel",
              "max-length": 16,
              "on-input": `this.value=this.value.replace(/[^0-9]/g,'');`,
              rules: "required|creditCard",
              message: {
              error_required: _ctx.$t('errors.required'),
              error_format_credit_card: _ctx.$t('errors.errorCreditCardFormat'),
            },
              value: _ctx.cardNo,
              "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.cardNo) = $event)),
              "is-only-number": true
            }, null, 8, ["placeholder", "on-input", "message", "value"])
          ]),
          _createElementVNode("div", _hoisted_13, [
            _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.$t("payment.cardName")), 1),
            _createVNode(_component_validation_text_field, {
              name: "cardName",
              placeholder: _ctx.$t('payment.cardNamePlaceholder'),
              rules: "required|holdername",
              message: {
              error_required: _ctx.$t('errors.required'),
              error_format_holdername: _ctx.$t('errors.errorFormatHolderName'),
            },
              value: _ctx.cardName,
              "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.cardName) = $event))
            }, null, 8, ["placeholder", "message", "value"])
          ]),
          _createElementVNode("div", _hoisted_15, [
            _createElementVNode("div", _hoisted_16, _toDisplayString(_ctx.$t("payment.expiryDate")), 1),
            _createElementVNode("div", _hoisted_17, [
              _createElementVNode("div", _hoisted_18, [
                _createVNode(_component_validation_text_field, {
                  name: "expireMonth",
                  placeholder: _ctx.$t('payment.monthPlaceholder'),
                  type: "tel",
                  "max-length": 2,
                  "on-input": `this.value=this.value.replace(/[^0-9]/g,'');`,
                  rules: "required",
                  message: {
                  error_required: _ctx.$t('errors.required'),
                },
                  value: _ctx.expireMonth,
                  "onUpdate:value": [
                    _cache[2] || (_cache[2] = ($event: any) => ((_ctx.expireMonth) = $event)),
                    _ctx.updateMonth
                  ],
                  onOnBlur: _ctx.onMonthBlur,
                  "is-only-number": true
                }, null, 8, ["placeholder", "on-input", "message", "value", "onUpdate:value", "onOnBlur"])
              ]),
              _createElementVNode("div", _hoisted_19, [
                _createVNode(_component_validation_text_field, {
                  name: "expireYear",
                  placeholder: _ctx.$t('payment.yearPlaceholder'),
                  type: "tel",
                  "max-length": 2,
                  "on-input": `this.value=this.value.replace(/[^0-9]/g,'');`,
                  rules: "required",
                  message: {
                  error_required: _ctx.$t('errors.required'),
                },
                  value: _ctx.expireYear,
                  "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.expireYear) = $event)),
                  onOnBlur: _ctx.onYearBlur,
                  "is-only-number": true
                }, null, 8, ["placeholder", "on-input", "message", "value", "onOnBlur"])
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_20, [
            _createElementVNode("div", _hoisted_21, _toDisplayString(_ctx.$t("payment.securityCode")), 1),
            _createElementVNode("div", _hoisted_22, [
              _createVNode(_component_validation_text_field, {
                name: "cvv",
                placeholder: _ctx.$t('payment.cvvPlaceholder'),
                class: "custom-input",
                type: "password",
                "max-length": 4,
                "on-input": `this.value=this.value.replace(/[^0-9]/g,'');`,
                rules: "required|cvv",
                message: {
                error_required: _ctx.$t('errors.required'),
                error_format_cvv: _ctx.$t('errors.errorCVV'),
              },
                "is-only-number": true,
                value: _ctx.securityCode,
                "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.securityCode) = $event))
              }, null, 8, ["placeholder", "on-input", "message", "value"]),
              _createElementVNode("div", {
                class: "info-icon",
                onMouseover: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.openTooltip && _ctx.openTooltip(...args))),
                onMouseleave: _cache[6] || (_cache[6] = ($event: any) => (_ctx.showInforPopup = false)),
                ref: "tooltip"
              }, [
                _createElementVNode("img", {
                  src: require('icons/primary-information-outline.svg'),
                  alt: "primary-information-outline"
                }, null, 8, _hoisted_23),
                _withDirectives(_createVNode(_component_menu_option_dropdown, {
                  "is-menu": false,
                  class: "info-popup",
                  style: _normalizeStyle({ top: `${_ctx.bottomTooltip}px` })
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t("payment.securutyCodeInfor")), 1)
                  ]),
                  _: 1
                }, 8, ["style"]), [
                  [_vShow, _ctx.showInforPopup]
                ])
              ], 544)
            ])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_24, [
        _createVNode(_component_PaymentButton, {
          text: _ctx.$t('payment.confirmPayment'),
          "is-loading": _ctx.isLoading,
          enabled: _ctx.isFullRequireField,
          class: "confirm-button",
          onClick: _ctx.submit
        }, null, 8, ["text", "is-loading", "enabled", "onClick"])
      ])
    ]),
    _: 1
  }, 8, ["title", "onOnHide", "onOnClickOutsidePopup"]))
}